import React from 'react'

import HomepageCarousel from './HomepageCarousel'
import ProductType from './ProductType'
import SalesBanner from './SalesBanner'
import ServiceBanner from './ServiceBanner'
import ExpSharing from './ExpSharing'

const Homepage = () => {
  return (
    <div>
      <HomepageCarousel />
      <ProductType />
      <SalesBanner />
      <ServiceBanner />
      <ExpSharing />
    </div>
  )
}

export default Homepage
