import React, { useState, useEffect } from 'react'

import tpBackend from '../../apis/tpBackend'
import { isDesktop } from '../../helpers/detectDevice'
import { DownloadBrochure, GetChat } from '../../assets/button'
import './product-type.css'

const minTouchDistance = 50
const minDragDistance = 60

const categoryList = [
  { id: 'Pick-up', text: 'รถกระบะ', modelList: [] },
  { id: 'PPV', text: 'รถอเนกประสงค์', modelList: [] },
  { id: 'Truck', text: 'รถบรรทุก', modelList: [] }
]

const ProductType = () => {
  const [selectedModel, setSelectedModel] = useState(0)
  const [selectedProductType, setSelectedProductType] = useState(0)
  const [productType, setProductType] = useState(null)

  let _swipe = { x: null }

  useEffect(async () => {
    const typeRes = await tpBackend.get(`/productType/${categoryList[selectedProductType].id}`)
    const update = { ...categoryList[selectedProductType], ...typeRes.data }
    setProductType(update)
  }, [selectedProductType])

  const nextPage = async () => {
    const modelList = productType.modelList
    const nextModel = (selectedModel + 1) % modelList.length
    setSelectedModel(nextModel)
  }

  const prevPage = async () => {
    const modelList = productType.modelList
    const prevModel = selectedModel - 1 < 0
      ? modelList.length + selectedModel - 1
      : selectedModel - 1
    setSelectedModel(prevModel)
  }

  const _onTouchStart = (e) => {
    const touch = e.touches[0]
    _swipe = { x: touch.clientX }
  }

  const _onTouchEnd = (e) => {
    const touch = e.changedTouches[0]
    const changed = touch.clientX - _swipe.x
    const absX = Math.abs(changed)
    if (absX > minTouchDistance) {
      if (changed > 0) {
        prevPage()
      } else {
        nextPage()
      }
    }
  }

  const _onDragStart = (e) => {
    _swipe.x = e.clientX
  }

  const _onDragEnd = (e) => {
    const changed = e.clientX - _swipe.x
    const absX = Math.abs(changed)
    if (absX > minDragDistance) {
      if (changed > 0) {
        prevPage()
      } else {
        nextPage()
      }
    }
  }

  const renderProductType = (productType, index) => {
    return (
      <button
        className={`product-type-button d-flex justify-content-center align-items-center ${selectedProductType === index ? 'active' : ''}`}
        onClick={() => {
          setSelectedModel(0)
          setSelectedProductType(index)
        }}
        key={productType.id}
      >
        <img className="product-type-icon" src={require(`../../assets/button/${productType.id.toLowerCase()}.svg`).default} />
        <div className='product-type-text'>
          <span>{productType.text}</span>
        </div>
      </button>
    )
  }

  const needScrollbar = (listLen) => {
    const containerWidth = window.innerWidth * 0.85
    const contentsWidth = listLen * 270
    return contentsWidth > containerWidth
  }

  const renderProductSlider = (modelList) => {
    const model = modelList[selectedModel]
    if (model !== undefined) {
      return (
        <div className='model-carousel carousel d-flex flex-column align-items-center aligns-self-center'>
          <ol className="carousel-indicators">
            {sliderIndexRender(modelList)}
          </ol>
          <img
            className='model-cover'
            src={process.env.REACT_APP_BACKEND_API + model.thumbnail.imgUrl}
          />
          <div className='d-flex justify-content-center'>{model.name}</div>
          <div
            className={`carousel-control-prev ${modelList.length > 1 ? '' : 'd-none'}`}
            onClick={prevPage} role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only">Previous</span>
          </div>
          <div className={`carousel-control-next ${modelList.length > 1 ? '' : 'd-none'}`}
            onClick={nextPage} role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="sr-only">Next</span>
          </div>
        </div>
      )
    } else {
      return ''
    }
  }

  const renderProductCarousel = (modelList) => {
    return (
      <div className={`${modelList.length > 0 ? 'model-carousel' : ''} d-flex flex-row ${needScrollbar(modelList.length) && isDesktop() ? 'scrollbar' : ''}`}>
        {modelList.map((x, index) => {
          const isActive = selectedModel === index ? 'active' : ''
          if (x.thumbnail !== null) {
            return (
              <div
                className={`model-container ${isActive}`}
                key={x.webCategory}
                onClick={() => setSelectedModel(index)}>
                <img
                  className={`model-cover ${isActive}`}
                  src={process.env.REACT_APP_BACKEND_API + x.thumbnail.imgUrl}
                  alt={x.name}
                />
                <div className='d-flex justify-content-center'><h5>{x.name}</h5></div>
              </div>
            )
          } else {
            return ('')
          }
        })}
      </div>
    )
  }

  const sliderIndexRender = (modelList) => {
    return modelList.map((item, index) => {
      return (
        <li
          key={index}
          data-slide-to={index}
          className={`${selectedModel === parseInt(index) ? 'active' : ''}`}
          onClick={() => { setSelectedModel(index) }}
        ></li>
      )
    })
  }

  const renderProductBanner = () => {
    const selectModel = productType.modelList.length > 0
      ? productType.modelList[selectedModel]
      : null
    if (selectModel !== null && 'cover' in selectModel) {
      return (
        <div
          id='wrapped-product-banner'
          className='overlap-container'
        >
          <img
            id='productBannerImg'
            className='product-banner'
            onDragStart={_onDragStart}
            onDragEnd={_onDragEnd}
            onTouchStart={_onTouchStart}
            onTouchEnd={_onTouchEnd}
            src={process.env.REACT_APP_BACKEND_API + selectModel.cover.imgUrl}
          />
          <div id='wrapped-triangle' className='overlap'>
            <div id='triangle-down-shadow' className='overlap'></div>
            <div id='triangle-down' className='overlap'></div>
          </div>
          <div className='wrapped-product-button second-row d-flex d-lg-none flex-row-reverse overlap'>
            <div
              className='product-button-container'
              onClick={() => { window.open('https://www.facebook.com/isuzutp', '_blank') }}
            >
              <img
                className='product-button'
                src={GetChat}
              />
            </div>
          </div>
          <div className='wrapped-product-button d-flex flex-row-reverse overlap'>
            <a href={process.env.REACT_APP_BACKEND_API + selectModel.brochureUrl} target='_blank' rel="noopener noreferrer">
            <div className='product-button-container'>
              <img
                className='brochure-button'
                src={DownloadBrochure}
              />
            </div>
            </a>
            <a href={'https://www.facebook.com/isuzutp'} target='_blank' rel="noopener noreferrer">
            <div className='product-button-container d-none d-lg-block'>
              <img
                className='product-button'
                src={GetChat}
              />
            </div>
            </a>
          </div>
        </div>
      )
    } else {
      return ''
    }
  }

  return (
    <div className='product-type-container'>
      <div className='section-header d-flex flex-row justify-content-center align-items-center'>
        <div className='section-header-line' />
        <span className='section-header-label'>เลือกรถอีซูซู...ที่คุณชอบ</span>
        <div className='section-header-line' />
      </div>
      <div className='d-flex flex-row justify-content-center'>
        {categoryList.map((x, index) => renderProductType(x, index))}
      </div>
      {productType !== null
        ? (isDesktop() ? renderProductCarousel(productType.modelList) : renderProductSlider(productType.modelList))
        : ''}
      {productType !== null ? renderProductBanner() : ''}
    </div>
  )
}

export default ProductType
