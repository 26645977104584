import React from 'react'
import { NavLink } from 'react-router-dom'

import './css/mobile-sidebar.css'

class MobileSidebar extends React.Component {
  onClickNav = () => {
    this.props.onClick()
  }

  render () {
    return (
      <div id='mobile-sidebar' className={`d-block ${this.props.showMenu ? 'active' : ''} shadow`}>
        <div className='d-flex flex-column align-items-end'>
          <NavLink to='/' exact onClick={this.onClickNav} activeClassName='active'>
            <div className='sidebar-menu d-flex justify-content-center align-items-center'>
              หน้าแรก
            </div>
          </NavLink>
          {/* <NavLink to="/products" onClick={this.onClickNav}>รุ่นรถ</NavLink>
          <NavLink to="/blog" onClick={this.onClickNav}>บทความ</NavLink> */}
          <NavLink to='/contact' onClick={this.onClickNav} activeClassName='active'>
            <div className='sidebar-menu d-flex justify-content-center align-items-center'>
              ติดต่อ
            </div>
          </NavLink>
        </div>
        <div id='mobile-sidebar-footer'/>
      </div>
    )
  }
}

export default MobileSidebar
