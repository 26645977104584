const getPreviewSize = (imgSize, previewCanvas) => {
  const { width, height, aspectRatio } = imgSize
  const { maxWidth, maxHeight } = previewCanvas
  if ((maxWidth === null) || (width / maxWidth < height / maxHeight)) {
    if (height > maxHeight) {
      return { width: maxHeight * aspectRatio, height: maxHeight, aspectRatio }
    } else {
      return { width, height, aspectRatio }
    }
  } else {
    if (width > maxWidth) {
      return { width: maxWidth, height: maxWidth / aspectRatio, aspectRatio }
    } else {
      return { width, height, aspectRatio }
    }
  }
}

module.exports = { getPreviewSize }
