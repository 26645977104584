const isDesktop = () => {
  const screenWidth = window.innerWidth
  return screenWidth > 991
}

const isMobile = () => {
  const screenWidth = window.innerWidth
  return screenWidth <= 991
}

module.exports = {
  isDesktop,
  isMobile
}
